<template>
  <v-container data-testid="fleet-vehicle-form">
    <v-row class="pt-3">
      <v-col cols="auto" class="card-title">
        {{ t('Title_.page.addNew') }}
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="px-4 py-2 shadow-regular">
          <v-form v-model="valid" ref="form">
            <VehicleDetails />
            <AdditionalInformation />
            <ContractInformation
              v-if="
                fleetVehiclesStore.vehicleDetails.acquirementType &&
                !isAcquirementTypePurchased
              "
            />
            <PurchaseInformation v-if="isAcquirementTypePurchased" />
            <InsuranceInformation v-if="isAcquirementTypePurchased" />
            <ObdInformation />
            <Documentation />
            <v-container>
              <v-row class="mt-1">
                <v-col>
                  <v-btn
                    data-testid="fleet-vehicle-form-cancel-btn"
                    class="cancel-variant-btn"
                    :disabled="mainStore.isPostFormInProgress"
                    :to="{ name: 'FleetVehiclesList' }"
                  >
                    {{ t('Action_.buttons.cancel') }}
                  </v-btn>

                  <v-btn
                    data-testid="fleet-vehicle-form-submit-btn"
                    class="main-action-btn ml-2"
                    @click="postForm()"
                    :disabled="mainStore.isPostFormInProgress || !valid"
                    :loading="mainStore.isPostFormInProgress"
                  >
                    {{ t('Action_.buttons.saveChanges') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import AdditionalInformation from './vehicleForm/additional-information.vue';
import ContractInformation from './vehicleForm/contract-information.vue';
import Documentation from './vehicleForm/documentation.vue';
import InsuranceInformation from './vehicleForm/insurance-information.vue';
import ObdInformation from './vehicleForm/obd-information.vue';
import PurchaseInformation from './vehicleForm/purchase-information.vue';
import VehicleDetails from './vehicleForm/vehicle-details.vue';
import languages from './locales/languages';
import { useMainStore } from '@/store/pinia/main.store.js';
import { usePopupDialogStore } from '@/store/pinia/popup-dialog.store.js';
import { useFleetVehiclesStore } from '@/store/pinia/fleet-vehicles.store.js';
import { useI18n } from 'vue-i18n-bridge';
import helpers from '@/helpers/helpers';
import { ref, onBeforeUnmount, computed } from 'vue';
import {
  useRoute,
  useRouter,
  onBeforeRouteLeave,
} from 'vue-router/composables';
import { amplitudeComposable } from '@/helpers/composables/amplitude.js';

const { trackPage } = amplitudeComposable();
const form = ref();
const route = useRoute();
const router = useRouter();
const mainStore = useMainStore();
const popupDialogStore = usePopupDialogStore();
const fleetVehiclesStore = useFleetVehiclesStore();
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Local variables ---
const ignoreFormChanges = ref(false);
const unmodifiedForm = ref(null);
const valid = ref(false);
const nextRoute = ref(null);

// --- Methods ---
const checkFormType = async () => {
  if (route.params.vehicleId) {
    try {
      await fleetVehiclesStore.getVehicleDetails(route.params.vehicleId);
      form.value.validate();
    } catch (error) {
      console.log(error);
    }
  }
  unmodifiedForm.value = { ...{}, ...fleetVehiclesStore.vehicleDetails };
  trackPage(fleetVehiclesStore.vehicleDetails.hubId);
};

const createOrEditVehicle = async (action) => {
  let payload = { ...{}, ...fleetVehiclesStore.vehicleDetails };

  payload = convertCurrencyValues(payload);
  payload = converthMonthDateValues(payload);

  payload.kmAllowance = parseInt(payload.kmAllowance);

  await fleetVehiclesStore[action](payload);
};

const convertCurrencyValues = (payload) => {
  const currencyFields = [
    'basePrice',
    'bankGuarantee',
    'extraPayment',
    'carRevenue',
    'insuranceDeductible',
    'monthlyInstallment',
    'updatedCarValue',
  ];

  currencyFields.forEach((field) => {
    payload[field] = helpers.convertCurrencyToFloat(payload[field]);
  });

  return payload;
};

const converthMonthDateValues = (payload) => {
  const monthDateFields = ['contractDate', 'carReturnedDate'];

  monthDateFields.forEach((field) => {
    payload[field] = helpers.convertMonthDateToDayDate(payload[field]);
  });

  return payload;
};

const redirectToVehicleList = () => {
  router.push({
    name: 'FleetVehiclesList',
  });
};

const postForm = async () => {
  ignoreFormChanges.value = true;
  mainStore.isPostFormInProgress = true;

  let action;
  // let actionTranslation;

  if (fleetVehiclesStore.vehicleDetails?.id) {
    action = 'editVehicle';
    // actionTranslation = 'Updated';
  } else {
    action = 'createVehicle';
    // actionTranslation = 'Created';
  }

  // const toastParams = {
  //   type: 'success',
  //   messages: {
  //     success: t(`Msg_.vehicle${actionTranslation}Success`),
  //     error: t(`Msg_.vehicle${actionTranslation}Fail`),
  //   },
  // };
  // this.$root.message.setToastParams(toastParams);

  try {
    await createOrEditVehicle(action);
    // this.$root.message.showToastNotification();
    redirectToVehicleList();
  } catch (error) {
    console.log(error);
  } finally {
    mainStore.isPostFormInProgress = false;
  }
};

const displayConfirmationDialog = (next) => {
  const actionBtn = () => {
    if (nextRoute.value) next();
  };
  const dialogTextId = fleetVehiclesStore.vehicleDetails?.id
    ? 'wantToCancelEditVehicle'
    : 'wantToCancelCreateVehicle';

  const params = {
    type: 'confirmation',
    title: t(`Action_.dialog.${dialogTextId}.title`),
    message: t(`Action_.dialog.${dialogTextId}.body`),
    reinforcement: t(`Action_.dialog.${dialogTextId}.reinforcement`),
    actionBtnText: t('Action_.buttons.proceed'),
    actionBtn: actionBtn,
  };

  popupDialogStore.params = params;
  popupDialogStore.displayDialog = true;
};

const clearNextRouteData = () => {
  nextRoute.value = null;
};

const isFormAltered = () => {
  if (unmodifiedForm.value) {
    return !helpers.compareTwoObjects(
      fleetVehiclesStore.vehicleDetails,
      unmodifiedForm.value
    );
  }
  return false;
};

// --- Computed ---
const isAcquirementTypePurchased = computed(() => {
  const acquirementTypePurchase =
    fleetVehiclesStore.vehicleResources.acquirementTypeValues.find(
      (acquirementType) => acquirementType.Name === 'Bought'
    );

  return (
    fleetVehiclesStore.vehicleDetails.acquirementType ===
    acquirementTypePurchase?.Value
  );
});

// --- Lifecycle hooks ---
checkFormType();

onBeforeUnmount(() => {
  mainStore.isPostFormInProgress = false;
  nextRoute.value = null;
});

// --- Routing guards ---
onBeforeRouteLeave((to, _, next) => {
  clearNextRouteData();
  if (!isFormAltered() || ignoreFormChanges.value) {
    nextRoute.value = to;
  }
  if (nextRoute.value) {
    next();
  } else {
    nextRoute.value = to;
    displayConfirmationDialog(next);
  }
});
</script>

<style scoped lang="scss"></style>
